import { ConsumerRoleType } from '../__generated__/symphonyTypes.v2';
import { RbacRole } from '../hooks/auth0/types';

/**
 * Returns the ConsumerRoleType from the auth0 RbacRole
 * @param rbacRole
 * @returns ConsumerRoleType
 */
export const getConsumerRoleType = (rbacRole?: RbacRole): ConsumerRoleType => {
  switch (rbacRole) {
    case 'FA_READ_ONLY':
    case 'FA_READ_WRITE':
      return ConsumerRoleType.FINANCIAL_ADVISOR;
    case 'IS_READ_WRITE':
      return ConsumerRoleType.INVESTMENT_SPECIALIST;
    default:
      return ConsumerRoleType.CLIENT;
  }
};
