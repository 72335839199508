import { createBreakpoints } from '@mui/system';
import { isIE } from 'react-device-detect';

import { createDefaultTheme, ThemeOptions } from '@sigfig/digital-wealth-core';

export { default as scotia } from './fonts/ttf/Scotia_Rg.ttf';
export { default as scotiaBold } from './fonts/ttf/Scotia_Bd.ttf';

const palette = {
  primary: {
    main: '#EC111A',
    dark: '#BE061B',
    light: '#FF575E',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#757575',
    dark: '#333333',
    light: '#757575',
    contrastText: '#FFFFFF',
    background: 'rgba(117, 117, 117, 0.08)',
  },
  text: {
    primary: '#333333',
    secondary: '#8C8C8C',
    disabled: '#BFBFBF',
  },
  action: {
    disabledBackground: '#F6F6F6',
    disabled: '#949494',
  },
  success: {
    main: '#138468',
    dark: '#3B873E',
    light: '#7BC67E',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#009DD6',
    dark: '#007EAB',
    light: '#64B6F7',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#FB6330',
    dark: '#C77700',
    light: '#FFB547',
    lightBg: '#FFF3EF',
    contrastText: '#000000, 87%',
  },
  error: {
    main: '#BE061B',
    dark: '#E31B0C',
    light: '#F88078',
    contrastText: '#FFFFFF',
  },
  background: {
    default: '#FAFAFA',
    light: '#FEECED',
  },
  other: {
    backdrop: '#000000',
    divider: '#F6F6F6',
    stroke: 'rgba(0, 0, 0, 0.23)',
  },
};

const components: ThemeOptions['components'] = {
  MuiAlert: {
    styleOverrides: {
      standard: {
        border: '1px solid',
        lineHeight: '21px',
        // override Typography that may be rendered by RteContent but not a Link
        '.MuiTypography-root:not(.MuiLink-root)': { color: 'inherit', lineHeight: 'inherit' },
      },
      standardInfo: { borderColor: palette.info.main, '.MuiAlert-icon': { color: palette.info.main } },
      standardError: { borderColor: palette.error.main, '.MuiAlert-icon': { color: palette.error.main } },
      standardSuccess: { borderColor: palette.success.main, '.MuiAlert-icon': { color: palette.success.main } },
      standardWarning: {
        borderColor: palette.warning.main,
        backgroundColor: palette.warning.lightBg,
        '.MuiAlert-icon': { color: palette.warning.main },
      },
      icon: { marginRight: 10 },
    },
  },
  MuiAlertTitle: {
    styleOverrides: {
      root: { fontSize: '16px', fontWeight: 700, lineHeight: '24px' },
    },
  },
  MuiButton: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      root: {
        borderRadius: 8,
        '&.MuiButton-sizeLarge': {
          fontFamily: 'Scotia-Bold',
          fontSize: 16,
          padding: '17px 36px',
          lineHeight: '20px',
        },
        '&.MuiButton-sizeMedium': {
          fontFamily: 'Scotia-Headline',
          fontSize: 14,
          padding: '15px 32px',
          lineHeight: '18px',
        },
        '&.MuiButton-sizeSmall': {
          fontSize: 14,
          padding: '13px 24px',
          lineHeight: '18px',
          '&.MuiButton-outlined': {
            padding: '12px 24px',
          },
        },
        '&.MuiButton-text': {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&.Mui-checked': {
          color: palette.info.dark,
        },
        pointerEvents: 'auto',
        color: palette.secondary.main,
        '&.Mui-disabled': {
          '.MuiSvgIcon-root': {
            fill: palette.other.stroke,
            position: isIE ? 'relative' : undefined,
            left: isIE ? '-5px' : undefined,
          },
          '.MuiIconButton-label': {
            height: 14,
            width: 14,
            margin: '5px',
            backgroundColor: palette.action.disabledBackground,
          },
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: '6px',
      },
      label: {
        fontFamily: 'Scotia-Regular',
        fontSize: '12px',
        lineHeight: '18px',
      },
      outlined: {
        borderWidth: '1.5px',
        borderColor: palette.secondary.main,
      },
      filled: {
        backgroundColor: '#F6F6F6',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      // Clear the Core style and use MuiButton style
      root: () => ({}),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${palette.other.divider}`,
        padding: 24,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginLeft: 0,
        marginRight: 0,
        '& .MuiTypography-root': {
          // override the MuiTypography override for error color
          color: 'inherit',
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: palette.other.stroke,
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        '&.Mui-disabled .MuiTypography-root': {
          // override the MuiTypography override for disabled color
          color: palette.text.disabled,
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: 16,
        fontWeight: 700,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        '&.Mui-focusVisible': { outline: isIE ? `${palette.primary.main} solid` : 'auto' },
      },
    },
    defaultProps: {
      underline: 'always',
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        '&.Mui-checked': {
          color: palette.info.dark,
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
          backgroundColor: palette.info.dark,
        },
      },
      switchBase: {
        '&.Mui-checked': {
          color: palette.info.dark,
        },
      },
      colorSecondary: {
        '&.Mui-checked': {
          color: palette.info.dark,
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        '&:first-of-type': {
          paddingLeft: 16,
        },
        '&': {
          borderColor: 'rgba(224, 224, 224, 1)',
        },
        '&.MuiTableCell-head': {
          padding: 16,
        },
        '&:last-of-type': {
          paddingRight: 16,
        },
        padding: '7px 16px',
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        '.MuiTableCell-head': {
          textTransform: 'none',
          fontSize: 16,
          fontWeight: 700,
        },
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        borderColor: palette.other.stroke,
        '&.Mui-selected': {
          color: palette.text.primary,
          backgroundColor: palette.other.divider,
        },
      },
    },
  },
  MuiToggleButtonGroup: {
    defaultProps: { color: 'secondary' },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        color: palette.text.primary,
      },
    },
  },
};

/**
 * The fontWeight is specified in the font-face fontFamily definition.
 */
const breakpoints = createBreakpoints({});
const typography: ThemeOptions['typography'] = {
  h1: {
    fontFamily: 'Scotia-Headline',
    fontSize: 48,
    lineHeight: '54px',
    [breakpoints.down('md')]: {
      fontSize: 36,
      lineHeight: '41px',
    },
  },
  h2: {
    fontFamily: 'Scotia-Headline',
    fontSize: 32,
    lineHeight: '40px',
    [breakpoints.down('md')]: {
      fontSize: 28,
      lineHeight: '35px',
    },
  },
  h3: {
    fontFamily: 'Scotia-Headline',
    fontSize: 24,
    lineHeight: '30px',
    [breakpoints.down('md')]: {
      fontSize: 22,
      lineHeight: '30px',
    },
  },
  h4: {
    fontFamily: 'Scotia-Headline',
    fontSize: 21,
    lineHeight: '26px',
    [breakpoints.down('md')]: {
      fontSize: 21,
      lineHeight: '26px',
    },
  },
  h5: {
    fontFamily: 'Scotia-Regular',
    fontSize: 20,
    lineHeight: '30px',
    [breakpoints.down('md')]: {
      fontSize: 20,
      lineHeight: '30px',
    },
  },
  h6: {
    fontFamily: 'Scotia-Regular',
    fontSize: 18,
    lineHeight: '28px',
  },
  subtitle1: {
    fontFamily: 'Scotia-Bold',
    fontSize: 18,
    lineHeight: '27px',
    [breakpoints.down('md')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  subtitle2: {
    fontFamily: 'Scotia-Bold',
    fontSize: 16,
    lineHeight: '20px',
    [breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: '21px',
    },
  },
  subtitle3: {
    fontFamily: 'Scotia-Bold',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '28px',
    [breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  body1: {
    fontFamily: 'Scotia-Regular',
    fontSize: 18,
    lineHeight: '27px',
    [breakpoints.down('md')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  body2: {
    fontFamily: 'Scotia-Regular',
    fontSize: 16,
    lineHeight: '24px',
    [breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: '21px',
    },
  },
  body3: {
    fontFamily: 'Scotia-Regular',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '22px',
    [breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: '18px',
    },
  },
  button: {
    fontFamily: 'Scotia-Bold',
  },
  caption: {
    fontFamily: 'Scotia-Regular',
    fontSize: 14,
    lineHeight: '21px',
    [breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: '18px',
    },
  },
  captionBold: {
    fontFamily: 'Scotia-Regular',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '21px',
  },
  tooltip: {
    fontFamily: 'Scotia-Regular',
    fontSize: 12,
    letterSpacing: 'normal',
    lineHeight: '16px',
  },
  buttonLarge: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '20px',
  },
};

const sfComponents: ThemeOptions = {
  sfAccount: {
    styles: {
      accountAlertMessage: {
        '.MuiTypography-root': { color: 'error.main' },
      },
    },
  },
  sfAccountDetails: {
    root: {},
    styles: {
      dropdownLabel: {
        fontWeight: 600,
      },
    },
  },
  sfAccountInfo: {
    styles: {
      infoChip: {
        textTransform: 'uppercase',
        fontWeight: 700,
      },
    },
  },
  sfChecklist: {
    typographyVariants: {
      item: 'body2',
    },
    styles: {
      title: { color: 'text.primary' },
    },
  },
  bnsHeader: {
    root: {
      '.MuiGrid-container': {
        '.MuiSelect-selectMenu': {
          pb: 0,
          pt: 0.75,
        },
        /**
         * Used in IE.
         */
        '.MuiInput-input': {
          color: palette.text.primary,
          typography: 'subtitle2',
        },
      },
    },
    styles: {
      accountSummaryButton: {
        mr: 5,
        my: 1,
        float: { sm: 'right' },
      },
      accountSummaryAdvisor: {
        alignItems: 'center',
        backgroundColor: 'background.light',
        display: 'flex',
        color: palette.primary.main,
        justifyContent: 'center',
        px: 5,
        py: 3,
      },
      dropdownLabel: {
        '.MenuItem-label.MuiTypography-root': {
          fontFamily: 'Scotia-Bold',
          fontSize: 16,
          fontWeight: 700,
        },
      },
    },
  },
  sfGoals: {
    styles: {
      card: {
        goalImage: {
          p: 1,
          height: { xs: '32px', md: '84px' },
          width: { xs: '32px', md: '84px' },
        },
      },
    },
  },
  sfKebabMenu: {
    styles: {
      button: {
        // Match the padding of the outlined button
        '&.MuiButton-sizeSmall': {
          '&, &.MuiButton-outlined': {
            p: '9px',
          },
        },
      },
    },
  },
  sfMadLibs: {
    root: {
      '.MuiFormControl-root .MuiAlert-root': { border: 'none' },
    },
    styles: {
      headingColor: 'text.primary',
      secondaryInputColor: palette.text.secondary,
    },
  },
  sfModal: {
    root: {
      '.MuiIconButton-label': {
        flexDirection: 'column',
      },
    },
    styles: {
      dialogTitle: {
        fontFamily: 'Scotia-Headline',
      },
    },
  },
  bnsMultipleSelectionPortfoliosTable: {
    root: {
      'div.MuiAlert-root': {
        border: `1px solid ${palette.primary.dark}`,
        mb: 0,
        py: 0,
        px: 1,
        '.MuiAlert-icon': {
          color: palette.error.dark,
        },
      },
    },
    styles: {},
  },
  sfOnboardingPageHeader: {
    styles: {
      icon: { my: 0, width: 100 },
    },
  },
  sfPerformanceChart: {
    styles: {
      lineColor: palette.info.dark,
    },
  },
  bnsPlayback: {
    styles: {
      iconSize: {
        height: 80,
        width: 80,
      },
    },
  },
  bnsAppropriateness: {
    styles: {
      iframeStyle: {
        border: 'none',
        background: palette.background.default,
        margin: '12px 0',
      },
    },
  },
  bnsPortfolioDetails: {
    styles: {
      iconSize: {
        height: 108,
        width: 108,
        [breakpoints.down('md')]: {
          height: 80,
          width: 80,
        },
      },
      label: {
        '.MuiTypography-caption': {
          color: palette.text.secondary,
        },
      },
      link: {
        alignSelf: 'center',
        color: 'info.dark',
        ml: 0.5,
        textDecorationColor: palette.info.dark,
      },
    },
  },
  bnsPortfolioSelection: {
    styles: {
      build: '#CC2B30',
      defend: '#1A1919',
      pay: '#8A8B8A',
      FIXED_INCOME: '#CC2B30',
      FOREIGN_EQUITY: '#1A1919',
      CANADIAN_EQUITY: '#8A8B8A',
      CASH_EQUIVALENT: '#B4B5B4',
    },
  },
  sfQuestionnaire: {
    styles: {
      card: {
        background: 'linear-gradient(0deg, rgba(111, 119, 121, 0.1), rgba(111, 119, 121, 0.1)), #FFFFFF',
        borderColor: 'rgba(0, 0, 0, 0.12)',
        minHeight: 300,
      },
      heading: {
        color: 'text.primary',
      },
    },
  },
  sfRiskSpectrum: {
    root: {
      '.MuiSvgIcon-root': {
        fontSize: '1.7rem',
      },
    },
    styles: {
      background: 'linear-gradient(90deg, #007EAB 0%, #138468 23.29%, #FB6330 61.52%, #BE0618 100%)',
      indicatorSecondaryRoot: {
        color: 'text.primary',
      },
    },
  },
  sfTooltip: {
    styles: {
      content: {
        p: 1,
        a: {
          color: 'text.primary',
          fontWeight: 700,
        },
      },
    },
  },
};

export const defaultTheme = createDefaultTheme({
  components,
  palette,
  typography,
  ...sfComponents,
});
