import { useMemo } from 'react';

import {
  QuestionnaireFilterTypes,
  useAccessibilityContent,
  useGetRecommendedProduct,
  useGetRiskBands,
  useLocale,
} from '@sigfig/digital-wealth-core';

import {
  FlowType,
  ModelPortfoliosFilterInput,
  ModelPortfoliosSortType,
  PaginationInput,
  PartnerProductEnum,
} from '../../../__generated__/symphonyTypes.v2';
import { useApp } from '../../../contexts/App';
import { useAuth0 } from '../../../hooks/auth0';
import {
  useGetBnsPortfolioSelectionContent,
  useGetModelPortfolioContent,
} from '../../../hooks/portfolio-selection/contentstack';
import { getBaseProduct, ModelPortfolio, useGetModelPortfolios } from '../../../hooks/portfolio-selection/symphony';
import { getAriaWhitelistedSecurityIds } from '../../../hooks/portfolio-selection/utils';
import { getConsumerRoleType } from '../../../utils';
import { ProductType } from '../../../utils/symphony/types';
import { AvailablePortfoliosData, FilterMode, GetAvailablePortfoliosResult, Portfolio } from '../types';
import { getPortfolios } from '../utils';

const productTypeMap = {
  [ProductType.GIC]: PartnerProductEnum.GIC,
  [ProductType.MUTUAL_FUND]: PartnerProductEnum.MUTUAL_FUND,
  [ProductType.SAVINGS]: PartnerProductEnum.HISA,
};

export const useGetAvailablePortfolios = ({
  combinedByBaseProduct,
  filterMode,
  flow,
  managedProductId,
  pagination,
  partyId,
  productType,
  search,
  skip,
  skipLiveRate,
  sort,
}: {
  combinedByBaseProduct?: boolean;
  filterMode: FilterMode;
  flow: FlowType;
  managedProductId: string;
  pagination: PaginationInput;
  partyId: string;
  productType?: ProductType;
  search?: string;
  skip?: boolean;
  skipLiveRate: boolean;
  sort?: ModelPortfoliosSortType;
}): GetAvailablePortfoliosResult => {
  const { brokerageAlias, contentOptions, program } = useApp();
  const { user } = useAuth0();
  const deliveryChannel = user?.deliveryChannel ?? '';
  const role = getConsumerRoleType(user?.role);

  const {
    data: portfolioSelectionContent,
    error: portfolioSelectionContentError,
  } = useGetBnsPortfolioSelectionContent({ skip, variables: contentOptions });
  const contentPortfolioSelection = portfolioSelectionContent?.all_bns_portfolio_selection?.items?.[0];
  const ariaWhitelistedSecurityIds = getAriaWhitelistedSecurityIds(portfolioSelectionContent);

  const { data: accessibilityContent, error: accessibilityContentError } = useAccessibilityContent(contentOptions);

  const { data: modelPortfolioContent, error: modelPortfolioContentError } = useGetModelPortfolioContent({
    variables: contentOptions,
    skip,
  });

  /**
   * The calculatedRecommendations field in the managedProduct can become stale after retake RTQ.
   * Since the saveRiskScores mutation returns PlanUpdateWorkflow response type, manual cache update is not possible.
   * As a result, the 'no-cache' fetchPolicy needs to be used to ensure up-to-date results.
   */
  const {
    data: recommendedProductData,
    loading: recommendedProductLoading,
    error: recommendedProductError,
  } = useGetRecommendedProduct({
    variables: {
      filter:
        flow === FlowType.RETAKE
          ? QuestionnaireFilterTypes.ONBOARDING_COMPLETED
          : QuestionnaireFilterTypes.RTQ_COMPLETED,
      managedProductId,
      partyId,
    },
    skip,
    fetchPolicy: 'no-cache', // TODO: Remove after DA2-6009 is completed.
  });

  const { data: riskBandsData, loading: riskBandsDataLoading, error: riskBandsDataError } = useGetRiskBands(
    contentOptions,
  );

  const [locale] = useLocale();

  const getFilters = (): ModelPortfoliosFilterInput => {
    if (recommendedProductData?.managedProduct?.calculatedRecommendations) {
      // riskScoreMin from calculatedRecommendations represents the floor of the Risk Spectrum,
      // contrary to riskScoreMin for filter represents minimum risk range of the portfolio.
      const {
        eligibleProductVariants,
        riskScore,
        riskScoreMin,
        scenarioId,
      } = recommendedProductData.managedProduct.calculatedRecommendations;

      const productFilter = {
        brokerageAlias,
        recommendedProductType: productType ? [productTypeMap[productType]] : undefined,
      };

      switch (filterMode) {
        case FilterMode.Recommendation:
          return {
            productFilter,
            recommendationFilter: {
              eligibleProductVariants,
              riskScoreMin,
              riskScoreMax: riskScore,
              scenarioId,
            },
          };
        case FilterMode.Suitable:
          return {
            productFilter: {
              role,
              ...productFilter,
            },
            recommendationFilter: {
              riskScoreMin,
              riskScoreMax: riskScore,
            },
          };
        case FilterMode.NonSuitable:
          return {
            productFilter: {
              role,
              ...productFilter,
            },
          };
      }
    }
    return {};
  };

  const {
    data: modelPortfoliosData,
    loading: modelPortfoliosLoading,
    error: modelPortfoliosError,
    refetch,
  } = useGetModelPortfolios({
    variables: {
      deliveryChannel,
      filters: getFilters(),
      flow,
      managedProductId,
      partyId,
      managedProductType: program,
      pagination,
      search,
      skipLiveRate,
      sort,
    },
    notifyOnNetworkStatusChange: !!search,
    skip: skip || !recommendedProductData?.managedProduct?.calculatedRecommendations,
  });

  const portfolioResult: GetAvailablePortfoliosResult['data'] = useMemo(() => {
    if (modelPortfoliosLoading || riskBandsDataLoading || recommendedProductLoading) {
      return;
    }

    if (accessibilityContent && contentPortfolioSelection && modelPortfolioContent) {
      const data: AvailablePortfoliosData = {
        accessibilityContent,
        contentPortfolioSelection,
        modelPortfolioContent,
        pageCount: 0,
        portfolios: [],
      };

      Object.assign(data, {
        planId: modelPortfoliosData?.managedProduct?.planId,
        planUpdateWorkflowId: modelPortfoliosData?.managedProduct?.planUpdateWorkflows[0]?.id,
        recommendedProductData,
        riskBandsData,
        selectedModelPortfolios: modelPortfoliosData?.managedProduct?.planUpdateWorkflows[0]?.selectedModelPortfolios,
      });

      const modelPortfolios = modelPortfoliosData?.modelPortfoliosV2.modelPortfolios ?? [];

      if (combinedByBaseProduct) {
        const combinedPortfolios = getCombinedPortfoliosBySeries(modelPortfolios);

        combinedPortfolios.forEach(arr => {
          (data.portfolios as Portfolio[][]).push(
            getPortfolios({
              ariaWhitelistedSecurityIds,
              contentPortfolioSelection,
              filterMode,
              locale,
              modelPortfolioContent,
              modelPortfolios: arr,
              recommendedProductData,
            }),
          );
        });
      } else {
        data.portfolios = getPortfolios({
          ariaWhitelistedSecurityIds,
          contentPortfolioSelection,
          filterMode,
          locale,
          modelPortfolioContent,
          modelPortfolios,
          recommendedProductData,
        });
      }

      data.pageCount = Math.ceil(
        (modelPortfoliosData?.modelPortfoliosV2.paginationContext.total ?? 0) /
          (modelPortfoliosData?.modelPortfoliosV2.paginationContext.limit ?? 1),
      );
      return data;
    }
  }, [
    accessibilityContent,
    ariaWhitelistedSecurityIds,
    combinedByBaseProduct,
    contentPortfolioSelection,
    filterMode,
    locale,
    modelPortfolioContent,
    modelPortfoliosData?.managedProduct?.planId,
    modelPortfoliosData?.managedProduct?.planUpdateWorkflows,
    modelPortfoliosData?.modelPortfoliosV2.modelPortfolios,
    modelPortfoliosData?.modelPortfoliosV2.paginationContext.limit,
    modelPortfoliosData?.modelPortfoliosV2.paginationContext.total,
    modelPortfoliosLoading,
    recommendedProductData,
    recommendedProductLoading,
    riskBandsData,
    riskBandsDataLoading,
  ]);

  return {
    data: portfolioResult,
    error:
      accessibilityContentError ??
      modelPortfolioContentError ??
      modelPortfoliosError ??
      portfolioSelectionContentError ??
      recommendedProductError ??
      riskBandsDataError,
    loading: modelPortfoliosLoading || riskBandsDataLoading || recommendedProductLoading,
    refetch,
  };
};

/**
 * Given the sort order and the key, return the sort order value.
 * @param sortOrder - The sort order.
 * @param key - The key to look up in the sort order.
 * @returns The sort position or 1000 as an arbitrary value to put the portfolio at the end of the sort order if the key is not found.
 */
export const getSortOrderValue = (sortOrder: Record<string, number>, key?: string | null): number => {
  const value = key ? sortOrder[key] : undefined;
  if (value) {
    return value;
  }
  console.warn(`[PortfolioSelection] - Unable to find sort order for key: ${key}. Returning arbitrary value of 1000.`);
  return 1000;
};

/**
 * Given an array of Model Portfolios, combine the portfolios by base product name.
 * @param modelPortfolios - The model portfolios to combine.
 * @returns An array of ModelPortfolio arrays grouped by base product name.
 */
const getCombinedPortfoliosBySeries = (modelPortfolios: ModelPortfolio[]) =>
  modelPortfolios.reduce((accumulator, current) => {
    if (!accumulator.length || !getBaseProduct(current)) {
      accumulator.push([current]);
      return accumulator;
    }

    let combined = false;
    for (const arr of accumulator) {
      if (arr.some(a => getBaseProduct(a) === getBaseProduct(current))) {
        accumulator[accumulator.indexOf(arr)].push(current);
        combined = true;
      }
      if (combined) {
        break;
      }
    }

    if (!combined) {
      accumulator.push([current]);
    }

    return accumulator;
  }, [] as ModelPortfolio[][]);
