import { Auth0User, RbacRole, rbacRoles } from './types';

interface FrontEndJwt {
  app: {
    DIGITAL_ADVISOR?: {
      rol: {
        rln: RbacRole;
      }[];
    };
  };
}

/**
 * If the role does not resolve from jwt, FA_READ_WRITE is used by default.
 */
export const defaultUserRole = 'FA_READ_WRITE';
export const getUserRole = (user: Auth0User): RbacRole => {
  try {
    const base64Url = user['https://fc.sigfig.com:frontEndJwt'].split('.')[1];
    const jwtData: Partial<FrontEndJwt> = JSON.parse(atob(base64Url));
    const role = jwtData.app?.DIGITAL_ADVISOR?.rol[0]?.rln;
    if (role && rbacRoles.includes(role)) {
      return role;
    }
  } catch (e) {
    console.warn(`User role could not be resolved; Defaulting to ${defaultUserRole}`, e);
  }
  return defaultUserRole;
};
