import {
  GetBnsPortfolioSelectionContent,
  GetBnsPortfolioSelectionContent_all_bns_portfolio_selection_items_ctas,
} from './contentstack/__generated__/getBnsPortfolioSelectionContent.v2';

export const getCtaLabel = (
  key: string,
  ctas?: (GetBnsPortfolioSelectionContent_all_bns_portfolio_selection_items_ctas | null)[] | null,
): string => ctas?.find(cta => key === cta?.key)?.label ?? '';

export const getAriaWhitelistedSecurityIds = (content?: GetBnsPortfolioSelectionContent): string[] =>
  content?.all_bns_portfolio_selection_aria_whitelist?.items?.[0]?.security_id?.filter((s): s is string => !!s) ?? [];
